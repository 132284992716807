import { CustomRouter } from "components/CustomRouter";
import { GlobalProvider } from "GlobalProvider";
import CaV3a, { caV3aPath } from "pages/ca-v3a";
import { Connect, connectPath } from "pages/Connect";
import { EsaLpV1, esaLpV1Path } from "pages/EsaLpV1";
import { FinalCheckout, finalCheckoutPath } from "pages/FinalCheckout";
import {
  Confirmation,
  confirmationPath,
} from "pages/FinalCheckout/steps/Confirmation";
import { Funnel, funnelPath } from "pages/Funnel";
import GlV3a, { glV3aPath } from "pages/gl-v3a";
import { Home, homePath } from "pages/Home";
import { HomeA, homeAPath } from "pages/HomeA";
import { HomeB, homeBPath } from "pages/HomeB";
import { HomeC, homeCPath } from "pages/HomeC";
import { HomeD, homeDPath } from "pages/HomeD";
// import { Interstitial, interstitialPath } from "pages/Interstitial";
import { Listing, listingPath } from "pages/Listing";
import { MobileB, mobileBPath } from "pages/MobileB";
import { NotFound } from "pages/NotFound";
import { OldHome, oldHomePath } from "pages/OldHome";
import { PrivacyPolicy, privacyPolicyPath } from "pages/PrivacyPolicy";
import {
  ProgressForRegistrationFunnel,
  progressForRegistrationFunnelPath,
} from "pages/ProgressForRegistrationFunnel";
import { TermsOfUse, termsOfUsePath } from "pages/TermsOfUse";
import { Therapist, therapistPath } from "pages/Therapist";
import { Route, Routes } from "react-router-dom";
import history from "utils/history";

function App() {
  return (
    <GlobalProvider>
      <CustomRouter history={history}>
        <Routes>
          <Route path={homePath} element={<Home />} />
          <Route path={glV3aPath} element={<GlV3a />} />
          <Route path={caV3aPath} element={<CaV3a />} />
          <Route path={homeAPath} element={<HomeA />} />
          <Route path={homeBPath} element={<HomeB />} />
          <Route path={homeCPath} element={<HomeC />} />
          <Route path={homeDPath} element={<HomeD />} />
          {/* <Route path={interstitialPath} element={<Interstitial />} /> */}
          <Route path={listingPath} element={<Listing />} />
          <Route path={therapistPath} element={<Therapist />} />
          <Route path={`${finalCheckoutPath}/*`} element={<FinalCheckout />} />
          <Route path={`${connectPath}/*`} element={<Connect />} />
          <Route path={`${funnelPath}/*`} element={<Funnel />} />
          <Route path={privacyPolicyPath} element={<PrivacyPolicy />} />
          <Route path={termsOfUsePath} element={<TermsOfUse />} />
          <Route path="*" element={<NotFound />} />
          <Route path={mobileBPath} element={<MobileB />} />
          <Route path={oldHomePath} element={<OldHome />} />
          <Route path={confirmationPath} element={<Confirmation />} />
          <Route path={esaLpV1Path} element={<EsaLpV1 />} />
          <Route
            path={progressForRegistrationFunnelPath}
            element={<ProgressForRegistrationFunnel />}
          />
        </Routes>
      </CustomRouter>
    </GlobalProvider>
  );
}

export default App;
