import { wrap } from "module";
import cn from "classnames";
import { FormatProfileField } from "components/FormatProfileField";
import { OfferDescription } from "components/OfferDescription/OfferDescription";
import { Button } from "components/UIKit/Button";
import { DisplayProduct, StepProps } from "pages/FinalCheckout/types";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  finalCheckoutService,
  GetProductArgs,
  Product,
  ProductTypes,
} from "services/finalCheckoutService";
import { visitorIdService } from "services/visitoridService";
import { showEsaLetterSelection } from "utils/env";
import { useSnapshot } from "valtio";

import styles from "./EsaLetterSelection.module.scss";

const basicLetterProductDisplay: DisplayProduct = {
  name: "esa_recommendation",
  description: "American Psychological Association DSM-5 assessment",
  product_type: "esa_recommendation",
  product_key: "esa_recommendation",
};

const premiumLetterProudctDisplay: DisplayProduct = {
  name: "esa_letter_with_video_call",
  description: "Video interview with therapist.",
  product_type: "esa_letter_with_video_call",
  product_key: "esa_letter_with_video_call",
};

export const EsaLetterSelection: FC<StepProps> = ({
  handlePrevStep,
  handleNextStep,
}) => {
  if (!showEsaLetterSelection) {
    handleNextStep();
  }
  const [searchParams] = useSearchParams();
  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );
  const visitorid = useMemo(
    () => searchParams.get("visitorid"),
    [searchParams]
  );
  visitorIdService.setVisitorID(visitorid);
  visitorIdService.trackEvent("ESALetterSelection", "", "", "", "");

  useEffect(() => {
    if (therapistId) {
      finalCheckoutService.setTherapistID(therapistId);
    }
  }, [therapistId]);

  const {
    chosenOffers,
    productData,
    chosenLetter: chosenProduct,
  } = useSnapshot(finalCheckoutService);

  useEffect(() => {
    const getProductArgs: GetProductArgs = {
      therapistKey: therapistId ?? "",
      visitorId: visitorid ?? "",
      state: "",
      cartid: "",
    };

    finalCheckoutService.getProductData(getProductArgs);
    document.getElementById("root")?.scrollTo({ top: 0 });
  }, [therapistId, visitorid]);

  const [selectedOffer, setSelectedOffer] = useState(false);
  const availableOffers: Product[] = [];

  const basicLetter = productData.get("esa_recommendation");

  const premiumLetter = productData.get("esa_letter_with_video_call");
  // Add premium first so it shows first.
  if (premiumLetter != null) {
    availableOffers.push(premiumLetter);
  }
  if (basicLetter != null) {
    availableOffers.push(basicLetter);
  }

  availableOffers.forEach((offer) => {
    if (offer.byline?.split("|") != null) {
      if (offer.byline?.split("|").length > 1) {
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart1 = offer.byline?.split("|")[0];
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart2 = offer.byline?.split("|")[1];
      } else if (offer.byline?.split("|").length > 0) {
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart1 = offer.byline?.split("|")[0];
        // eslint-disable-next-line no-param-reassign
        offer.bylinePart2 = "";
      }
    }
  });

  const handleClick = () => {
    handleNextStep();
  };

  return (
    <section className={cn(styles.sectionOffers, "default-typography")}>
      <div className="container">
        <h1 className="h2 fw-semibold text-xxs-center mb-20 mb-xxs-40">
          3 Ways to get your ESA Letter
        </h1>

        <div className="row gutter-16 justify-content-center">
          {availableOffers.map((offer) => (
            <div
              className="col-xxs-10 col-sm-6 col-layout-flip-5 col-lg-4 d-flex"
              key={offer.name}
            >
              <div className={styles.cardOffer}>
                {offer.headline != null && offer.headline !== "" && (
                  <div className="row gutter-40 justify-content-center">
                    <div className="col-auto">
                      <div className={styles.cardOffer_headline_container}>
                        <span
                          className={styles.cardOffer_headline}
                          dangerouslySetInnerHTML={{
                            __html: offer.headline ?? "",
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                )}

                <div className={styles.cardOffer__topContent}>
                  {offer.product_type === "esa_letter_with_video_call" && (
                    <div className="row gutter-40 justify-content-center">
                      <div className="col-auto">
                        <div className={styles.cardOffer__icon}>
                          <span className="icomoon-video"></span>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className={styles.cardOffer__icon}>
                          <span className="icomoon-phone"></span>
                        </div>
                      </div>
                    </div>
                  )}

                  {offer.product_type === "esa_recommendation" && (
                    <div className="row gutter-40 justify-content-center">
                      <div className="col-auto">
                        <div className={styles.cardOffer__icon}>
                          <span className="icomoon-chat"></span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles.cardOffer__titleHolder}>
                    <h4 className={styles.cardOffer__title}>{offer.name}</h4>

                    <ul className={styles.cardOffer__descriptionList}>
                      <li
                        dangerouslySetInnerHTML={{
                          __html: offer.bylinePart1 ?? "",
                        }}
                      ></li>
                      {offer.bylinePart2 !== null &&
                        offer.bylinePart2 !== "" && (
                          <li
                            dangerouslySetInnerHTML={{
                              __html: offer.bylinePart2 ?? "",
                            }}
                          ></li>
                        )}
                    </ul>
                  </div>

                  <div className={styles.cardOffer__narrowСontent}>
                    <div className={styles.cardOffer__infoList}>
                      <OfferDescription
                        description={offer.description}
                        className={styles.cardOffer__description}
                      ></OfferDescription>
                    </div>
                  </div>
                </div>

                <div className={styles.cardOffer__bottomContent}>
                  <div className={styles.cardOffer__narrowСontent}>
                    <h2 className={styles.cardOffer__price}>${offer.price}</h2>

                    <label className={styles.checkboxLikeButton}>
                      <input
                        type="checkbox"
                        className={styles.checkboxLikeButton__input}
                        checked={false}
                        onChange={() => {
                          finalCheckoutService.deSelectOffer(
                            finalCheckoutService.chosenLetter
                          );
                          // select current product
                          if (finalCheckoutService.isOfferSelected(offer.key)) {
                            finalCheckoutService.deSelectOffer(offer.key);
                          }
                          finalCheckoutService.selectOffer(offer.key);
                          finalCheckoutService.setChosenLetter(offer.key);

                          handleClick();
                        }}
                      />

                      <span className={styles.checkboxLikeButton__block}>
                        <span
                          className={
                            styles.checkboxLikeButton__notSelectedYetBlock
                          }
                        >
                          Select
                        </span>
                        <span
                          className={styles.checkboxLikeButton__selectedBlock}
                        >
                          <span
                            onClick={handleClick}
                            className={cn(
                              styles.checkboxLikeButton__selectedBlockText,
                              "btn-with-checkmark"
                            )}
                          >
                            Selected
                          </span>
                          <span
                            className={
                              styles.checkboxLikeButton__selectedBlockUncheck
                            }
                          >
                            <span className="icomoon-remove"></span>
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          className={styles.sectionOffers__bottomButton}
          color="green-light"
          onClick={handlePrevStep}
        >
          Back
        </Button>
      </div>
    </section>
  );
};
