import cardInfoIcon1 from "assets/images/esa-lp-v1/card-info/1.svg";
import cardInfoIcon2 from "assets/images/esa-lp-v1/card-info/2.svg";
import cardInfoIcon3 from "assets/images/esa-lp-v1/card-info/3.svg";
import cardInfoIcon4 from "assets/images/esa-lp-v1/card-info/4.svg";
import cardInfoIcon5 from "assets/images/esa-lp-v1/card-info/5.svg";
import cardInfoIcon6 from "assets/images/esa-lp-v1/card-info/6.svg";
import cardInfoIcon7 from "assets/images/esa-lp-v1/card-info/7.svg";
import cardInfoIcon8 from "assets/images/esa-lp-v1/card-info/8.svg";
import cardInfoIcon9 from "assets/images/esa-lp-v1/card-info/9.svg";
import sectionProcessImg from "assets/images/esa-lp-v1/section-process-img.jpg";
import sectionTrustedImg from "assets/images/esa-lp-v1/section-trusted-img.jpg";
import sectionWelcomeImg from "assets/images/esa-lp-v1/section-welcome-img.jpg";
import stars from "assets/images/esa-lp-v1/stars.svg";
import cn from "classnames";
import { Logo } from "components/Logo/Logo";
import { Button } from "components/UIKit/Button";
import { useGA } from "hooks/useGA";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { visitorIdService } from "services/visitoridService";

import styles from "./EsaLpV1.module.scss";

export const esaLpV1Path = "/esa-lp-v1";

export const EsaLpV1 = () => {
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  let VisitorID = "";

  visitorIdService.getVisitorID(false).then((vid) => {
    VisitorID = vid;
  });
  visitorIdService.trackEvent("LandingPage", "Home/esa-lp-v1", "", "", "");

  const faqData = [
    {
      question: "Is this completely legal?",
      answer:
        "Yes, ESA Letters are completely legal and your landlord is required by law to accomodate your pet if you are approved.",
    },
    {
      question: "What kind of therapist will provide my certification?",
      answer:
        "All TheraPass therapists are state licensed and certified to ensure you have the best experience and all ESA letters are completely legitimate.",
    },
    {
      question: "When will I get my ESA Letter?",
      answer:
        "Once you get pre-approved you will be connected with your ESA therapist usually within minutes, they will assign you your letter. Most people have their ESA letter within a few hours of starting the process.",
    },
    {
      question: "Do you offer a money back guarantee?",
      answer:
        "Yes, we have a 100% money back guarantee that your landlord will accept our ESA letter for your pet.",
    },
    {
      question: "What is an ESA letter?",
      answer:
        "An ESA Letter stands for Emotional Support Animal Letter. These letters are issued by licensed therapists and give renters the right to have an ESA in their residence without restriction or needing to pay pet rent.",
    },
    {
      question: "How do I qualify for an ESA letter?",
      answer:
        "We offer a Free Pre-Approval process that takes less than 5 minutes to find out if you qualify. Many common occurring things like depression and or anxiety will qualify you.",
    },
    {
      question: "Does my ESA letter transfer from state to state?",
      answer: "Yes, your letter can be transferred to another state.",
    },
    {
      question: "How long does an ESA Letter approval last?",
      answer:
        "ESA letter approvals last for one year - we offer an easy subscription service that will automatically renew yours for you if you’d like that way you are never not covered.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  interface FaqItemProps {
    question: string;
    answer: string;
    isActive: boolean;
    sectionIndex: number;
    setActiveIndex: any;
  }

  const AccordionItem = (faqItem: FaqItemProps) => {
    const toggleAccordion = () => {
      const nextIndex: any = faqItem.isActive ? null : faqItem.sectionIndex;
      setActiveIndex(nextIndex);
    };

    return (
      <li
        className={cn(
          styles.sectionFaq__faqListItem,
          faqItem.isActive ? styles.active : ""
        )}
      >
        <button
          className={cn(styles.sectionFaq__faqHeader, "h3 fw-bold")}
          onClick={toggleAccordion}
        >
          {faqItem.question}
        </button>

        {faqItem.isActive && (
          <div className={styles.sectionFaq__faqBody}>
            <div className="text-block h4 fw-base">
              <p>{faqItem.answer}</p>
            </div>
          </div>
        )}
      </li>
    );
  };

  const redirectProgressFunnel = () => {
    visitorIdService.trackEvent(
      "Home",
      "Exit",
      "/progress-for-registration-funnel",
      "",
      ""
    );
    navigate("/progress-for-registration-funnel", {
      state: { withScroll: false },
    });
  };

  useEffect(() => {
    visitorIdService.trackEvent("Home", "Visit", esaLpV1Path, "", "");
  }, []);

  return (
    <div className="default-typography text-blue-dark bg-gray-110">
      <section className={cn(styles.section, styles.sectionWelcome)}>
        <div className={styles.sectionWelcome__imageBlock}>
          <div className={styles.sectionWelcome__imageWrap}>
            <img src={sectionWelcomeImg} alt="Image" className="of-cover" />
          </div>

          <div className="container">
            <div className={styles.sectionWelcome__logoBlock}>
              <Logo className={styles.sectionWelcome__logo} />
            </div>
          </div>
        </div>

        <div className={styles.sectionWelcome__bannerWrap}>
          <div className="container">
            <div className={styles.sectionWelcome__banner}>
              <img
                src={stars}
                alt="Stars"
                className={styles.sectionWelcome__stars}
              />

              <h4 className="fw-bold mb-25 mb-xxs-40">
                Trusted By 2 Million Happy Pet Owners
              </h4>

              <h1 className="fw-bold mb-20">
                Tired of battling pet rent and rental rejections?
              </h1>

              <div className="text-block h4 fw-normal mb-25 mb-xxs-40">
                <p>
                  With our quick, easy and 50 state legal ESA letter service,
                  enjoy freedom from pet fees and breeze through rental
                  approvals.
                </p>
              </div>

              <div className="mb-25 mb-xxs-40">
                <Button
                  className="w-100 w-xxs-auto"
                  onClick={redirectProgressFunnel}
                >
                  GET STARTED
                </Button>
              </div>

              <div className="text-block h4 fw-normal">
                <p>
                  Quick 5 Minute Pre-Qualification | Get ESA Letter upon
                  Approval Today
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(styles.section, styles.sectionInfo)}>
        <div className="container">
          <div className={styles.sectionInfo__mainContent}>
            <div className="row align-items-center mb-30 mb-layout-flip-50">
              <div className="col-layout-flip-5 order-2 order-layout-flip-1">
                <h3 className="mb-20 fw-bold">
                  Legally Rent Without Pet Restrictions
                </h3>
                <div className="text-block h4">
                  <p>
                    An ESA letter is a completely legal and legitimate option
                    that opens doors for renters by exempting them from pet
                    rent, saving you thousands of dollars a year and gaining
                    approval for housing that typically restricts pets.{" "}
                  </p>
                </div>
              </div>

              <div className="col-layout-flip-7 order-1 order-layout-flip-2">
                <div
                  className={cn(
                    styles.videoIframeHolder,
                    "mb-30 mb-layout-flip-0"
                  )}
                >
                  <iframe
                    src="https://player.vimeo.com/video/868938270?h=66e7276bd8"
                    className="of-cover"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className={styles.sectionInfo__cardsContent}>
              <div className="row gx-15">
                <div className="col-sm-4 d-flex">
                  <div className={cn(styles.cardInfo, "text-blue-deep")}>
                    <div className={styles.cardInfo__icon}>
                      <div className={styles.cardInfo__iconHolder}>
                        <img
                          src={cardInfoIcon1}
                          alt="Icon"
                          className="of-contain"
                        />
                      </div>
                    </div>

                    <h4 className="fw-semibold mb-5">Quick and Easy</h4>
                    <div className="text-block text-xl lh-sm">
                      <p>No waiting - Get your ESA letter Today!</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 d-flex">
                  <div className={cn(styles.cardInfo, "text-blue-deep")}>
                    <div className={styles.cardInfo__icon}>
                      <div className={styles.cardInfo__iconHolder}>
                        <img
                          src={cardInfoIcon2}
                          alt="Icon"
                          className="of-contain"
                        />
                      </div>
                    </div>

                    <h4 className="fw-semibold mb-5">Money Back Guarantee</h4>
                    <div className="text-block text-xl lh-sm">
                      <p>100% Guaranteed landlord acceptance</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 d-flex">
                  <div className={cn(styles.cardInfo, "text-blue-deep")}>
                    <div className={styles.cardInfo__icon}>
                      <div className={styles.cardInfo__iconHolder}>
                        <img
                          src={cardInfoIcon3}
                          alt="Icon"
                          className="of-contain"
                        />
                      </div>
                    </div>

                    <h4 className="fw-semibold mb-5">
                      Certified Professionals
                    </h4>
                    <div className="text-block text-xl lh-sm">
                      <p>Backed by State Licensed Therapists</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button
                className="w-100 w-xxs-auto"
                onClick={redirectProgressFunnel}
              >
                GET STARTED
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(styles.section, styles.sectionProcess)}>
        <div className="container">
          <div className={styles.sectionProcess__imgBlock}>
            <img src={sectionProcessImg} alt="Image" className="of-cover" />
          </div>

          <div className={styles.sectionProcess__contentBlockWrap}>
            <div className={styles.sectionProcess__contentBlock}>
              <div className="text-center mb-20 mb-sm-50">
                <h3 className="text-blue-deep mb-15 fw-bold">
                  Fast, Simple, 100% Legal, and Easy
                </h3>
                <div className="text-block h4 fw-base">
                  <p>
                    Get your ESA Letter Today with our Free Pre-Approval
                    process.
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <div className={styles.cardProcess}>
                    <div className={styles.cardProcess__number}>1</div>
                    <h4 className="fw-bold">
                      Text, Zoom or Call with a licensed Therapist
                    </h4>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className={styles.cardProcess}>
                    <div className={styles.cardProcess__number}>2</div>
                    <h4 className="fw-bold">
                      Receive your ESA Letter within minutes
                    </h4>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className={styles.cardProcess}>
                    <div className={styles.cardProcess__number}>1</div>
                    <h4 className="fw-bold">
                      Get the rental of your dreams pet rent free!
                    </h4>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Button
                  className="w-100 w-xxs-auto"
                  onClick={redirectProgressFunnel}
                >
                  GET STARTED
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={cn(styles.section, "bg-blue-snow py-xl-85")}>
        <div className="container">
          <h3 className="text-blue-deep fw-bold mb-20 mb-sm-50 text-center text-sm-start">
            3 Ways to Get Your ESA Letter
          </h3>

          <div className="row gx-15">
            <div className="col-sm-4 d-flex">
              <div
                className={cn(
                  styles.cardInfo,
                  styles.horizontalOnMobile,
                  "text-blue-dark"
                )}
              >
                <div className={styles.cardInfo__icon}>
                  <div className={styles.cardInfo__iconHolder}>
                    <img
                      src={cardInfoIcon4}
                      alt="Icon"
                      className="of-contain"
                    />
                  </div>
                </div>

                <h4 className="fw-semibold mb-5">Text Message</h4>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Take APA online assessment via text DSM-5 Cross-Cutting
                    Symptom test
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div
                className={cn(
                  styles.cardInfo,
                  styles.horizontalOnMobile,
                  "text-blue-dark"
                )}
              >
                <div className={styles.cardInfo__icon}>
                  <div className={styles.cardInfo__iconHolder}>
                    <img
                      src={cardInfoIcon5}
                      alt="Icon"
                      className="of-contain"
                    />
                  </div>
                </div>

                <h4 className="fw-semibold mb-5">Phone Call</h4>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Connect immediately to a therapist by phone for a quick
                    evaluation
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div
                className={cn(
                  styles.cardInfo,
                  styles.horizontalOnMobile,
                  "text-blue-dark"
                )}
              >
                <div className={styles.cardInfo__icon}>
                  <div className={styles.cardInfo__iconHolder}>
                    <img
                      src={cardInfoIcon6}
                      alt="Icon"
                      className="of-contain"
                    />
                  </div>
                </div>

                <h4 className="fw-semibold mb-5">Video Call</h4>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Jump into a live video session now with your mobile – no
                    waiting
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-20">
            <Button
              className="w-100 w-xxs-auto"
              onClick={redirectProgressFunnel}
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </section>

      <section className={cn(styles.section, styles.sectionTrusted)}>
        <div className="container">
          <h2 className="h1 text-center fw-bold mb-20 mb-md-50">
            Trusted by 2 million+<br></br> Happy Pet Owners
          </h2>

          <div className={styles.sectionTrusted__bannerWrap}>
            <div className={styles.sectionTrusted__banner}>
              <img src={sectionTrustedImg} alt="Image" className="of-cover" />
            </div>

            <div className={styles.sectionTrusted__bannerContent}>
              <img
                src={stars}
                alt="Stars"
                className={styles.sectionTrusted__stars}
              />
              <blockquote className={styles.sectionTrusted__quote}>
                <p>
                  “With TheraPass I always have my choice of how to connect with
                  a therapist - by phone, video, or text message. Getting my ESA
                  Letter took just a couple of minutes - no waiting!”
                </p>
                <footer>- Andrea B.</footer>
              </blockquote>
            </div>
          </div>

          <h3 className="fw-bold mb-20 mb-layout-flip-40 text-center text-layout-flip-start">
            Why TheraPass?
          </h3>

          <div className="row gx-15">
            <div className="col-sm-4 d-flex">
              <div className={cn(styles.cardInfo, styles.blueBg)}>
                <div className={styles.cardInfo__icon}>
                  <div className={styles.cardInfo__iconHolder}>
                    <img
                      src={cardInfoIcon7}
                      alt="Icon"
                      className="of-contain"
                    />
                  </div>
                </div>

                <h2 className="fw-bold mb-5">+2 million</h2>
                <div className="text-block text-xl lh-sm">
                  <p>
                    ESA & Disability<br></br> Assessments
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div className={cn(styles.cardInfo, styles.blueBg)}>
                <div className={styles.cardInfo__icon}>
                  <div className={styles.cardInfo__iconHolder}>
                    <img
                      src={cardInfoIcon8}
                      alt="Icon"
                      className="of-contain"
                    />
                  </div>
                </div>

                <h2 className="fw-bold mb-5">Certified</h2>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Therapists & Mental<br></br> Health Professionals
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex">
              <div className={cn(styles.cardInfo, styles.blueBg)}>
                <div className={styles.cardInfo__icon}>
                  <div className={styles.cardInfo__iconHolder}>
                    <img
                      src={cardInfoIcon9}
                      alt="Icon"
                      className="of-contain"
                    />
                  </div>
                </div>

                <h2 className="fw-bold mb-5">+6</h2>
                <div className="text-block text-xl lh-sm">
                  <p>
                    Years in<br></br> Business
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mt-20">
            <Button
              className="w-100 w-xxs-auto"
              onClick={redirectProgressFunnel}
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </section>

      <section className={cn(styles.section, styles.sectionFaq)}>
        <div className="container">
          <h3 className="text-center mb-30 mb-sm-50">FAQs</h3>

          <ul className={styles.sectionFaq__faqList}>
            {faqData.map((faqItem, index) => (
              <AccordionItem
                key={index}
                question={faqItem.question}
                answer={faqItem.answer}
                isActive={index === activeIndex}
                sectionIndex={index}
                setActiveIndex={setActiveIndex}
              />
            ))}
          </ul>

          <div className="text-center mt-30 mt-sm-55">
            <Button
              className="w-100 w-xxs-auto"
              onClick={redirectProgressFunnel}
            >
              GET STARTED
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};
