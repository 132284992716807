import { TherapistCardSuccess } from "components/TherapistCardSuccess";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { funnelPath } from "pages/Funnel";
import { therapistProfileService } from "services/therapistProfileService";
import { useSnapshot } from "valtio";
import progressStyles from "../../ProgressForRegistrationFunnel/ProgressForRegistrationFunnel.module.scss";
import styles from "./Success.module.scss";

export const successPath = "/success";

export const Success = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);
  const navigate = useNavigateWithQuery();
  const goToNextStep = () => navigate(funnelPath);

  const subtitleName = [
    `${currentProfile?.user.first_name} ${currentProfile?.user.last_name}`,
    currentProfile?.credentials_choice?.toUpperCase(),
  ]
    .filter((item) => item)
    .join(", ");
  // This will trigger the conversion
  // This will trigger the conversion
  window.gtag("event", "conversion", {
    send_to: "AW-11025675463/UTgYCOmpw4kYEMfpuYkp",
  });
  return (
    <div className="default-typography">
      <div className="row justify-content-center">
        <div className="col-xs-10 col-md-8">
          <div
            className="text-center mb-40 mb-sm-60"
            ref={(node) =>
              node?.style.setProperty("margin-bottom", "56px", "important")
            }
          >
            <h1 className="h2 mb-10" style={{ fontSize: "24px" }}>
              Your message has been sent!
            </h1>
            <h4
              className="mb-0"
              style={{ fontSize: "16px", textAlign: "center" }}
            >
              {" "}
              Thank you, your ESA therapist {subtitleName}, has received your
              info! Please have your mobile phone nearby.{" "}
              {currentProfile?.user.first_name} will be texting you shortly.
            </h4>
          </div>
        </div>
      </div>

      <div className={progressStyles.sectionProgress__content}>
        <div className={progressStyles.sectionProgress__progressBlock}>
          <div
            className={progressStyles.sectionProgress__progressStrip}
            style={{ width: "30%" }}
          ></div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        {currentProfile && <TherapistCardSuccess therapist={currentProfile} />}
      </div>
    </div>
  );
};
