import { TherapistCard } from "components/TherapistCard";
import { TherapistCardPetSuccess } from "components/TherapistCardPetSuccess";

import { Button } from "components/UIKit/Button";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { useMobileResponsive, useTabletResponsive } from "hooks/useResponsive";
import { capitalize } from "lodash";
import { StepProps } from "pages/FinalCheckout/types";
import { FC } from "react";
import { therapistProfileService } from "services/therapistProfileService";
import { useSnapshot } from "valtio";
import styles from "../../FinalCheckout.module.scss";
import localStyles from "./PetsConfirmation.module.scss";

export const confirmPath = "/confirm";

export const PetsConfirmation: FC<StepProps> = ({
  handlePrevStep,
  handleNextStep,
}) => {
  const { currentProfile } = useSnapshot(therapistProfileService);

  const subtitleName = [
    `${currentProfile?.user.first_name} ${currentProfile?.user.last_name}`,
    capitalize(currentProfile?.title),
  ]
    .filter((item) => item)
    .join(", ");

  const { isMobile } = useMobileResponsive();
  const { isTablet } = useTabletResponsive();

  return (
    <>
      {(isMobile && (
        <div className={`${styles.root} container`}>
          <div className={localStyles.confirmPage}>
            <div>
              <h1 className={localStyles.title}>
                Your information has been sent!
              </h1>
              <p className={localStyles.subtitle}>
                Thank you! Keep your mobile phone nearby. Your ESA therapist{" "}
                {currentProfile?.user.first_name} is reviewing now and will text
                you shortly.
              </p>

              {currentProfile && (
                <TherapistCardPetSuccess therapist={currentProfile} />
              )}
            </div>
          </div>
        </div>
      )) ||
        (isTablet && (
          <div className={`${styles.root}`}>
            <div className={`${localStyles.confirmPage} container`}>
              <div>
                <h1 className={localStyles.title}>
                  Your information has been sent!
                </h1>
                <p className={localStyles.subtitle}>
                  Thank you! Keep your mobile phone nearby. Your ESA therapist{" "}
                  {currentProfile?.user.first_name} is reviewing now and will
                  text you shortly.
                </p>
                {currentProfile && (
                  <TherapistCardPetSuccess therapist={currentProfile} />
                )}
              </div>
            </div>
          </div>
        )) || (
          <div className={styles.root}>
            <div className={localStyles.confirmPage}>
              <div>
                <h1 className={localStyles.title}>
                  Your information has been sent!
                </h1>
                <p className={localStyles.subtitle}>
                  Thank you! Keep your mobile phone nearby. Your ESA therapist{" "}
                  {currentProfile?.user.first_name} is reviewing now and will
                  text you shortly.
                </p>

                {currentProfile && (
                  <TherapistCardPetSuccess therapist={currentProfile} />
                )}
              </div>
            </div>
          </div>
        )}
      {/* // <div className={styles.root}>
    //   <div className={localStyles.confirmPage}>
    //     <div>
    //       <h1 className={localStyles.title}>Your message has been sent!</h1>
    //       <p className={localStyles.subtitle}>
    //         Your Information has been sent to {subtitleName}. Please allow a
    //         couple minutes for your ESA therapist&nbsp;{}
    //         {currentProfile?.user.first_name}&nbsp;to text you.
    //       </p>

    //       {currentProfile && (
    //         <TherapistCardSuccess therapist={currentProfile} />
    //       )}
    //     </div>
    //   </div>
    // </div> */}
    </>
  );
};
