import { useGA } from "hooks/useGA";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { finalCheckoutService } from "services/finalCheckoutService";
import { visitorIdService } from "services/visitoridService";
import { EsaLetterSelection } from "./steps/EsaLetterSelection";
import { Offers } from "./steps/Offers";
import { PaymentInfo } from "./steps/PaymentInfo";
import { PetsConfirmation } from "./steps/PetsConfirmation";
import { PetsPage } from "./steps/PetsPage";

export const finalCheckoutPath = "/final_checkout";

interface StepProps {
  handlePrevStep: () => void;
  handleNextStep: () => void;
}

const steps: FC<StepProps>[] = [
  PetsPage,
  PetsConfirmation,
  EsaLetterSelection,
  Offers,
  /* AssistanceAnimalGear, */
  PaymentInfo,
  // Confirmation,
];

export const FinalCheckout = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const StepComponent = steps[currentStep];
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const returning = query.get("returning");
  const chosenproduct = query.get("prod");
  useGA().ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };
  if (returning === "true" && currentStep === 0) {
    setCurrentStep(2);
    if (chosenproduct != null)
      finalCheckoutService.setChosenLetter(chosenproduct);
  }

  useEffect(() => {
    visitorIdService.trackEvent(
      "final_checkout",
      currentStep.toString(),
      "",
      "",
      ""
    );
  }, [currentStep]);

  const handlePrevStep = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  return (
    <StepComponent
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
    />
  );
};
