export const apiEndpoint =
  process.env.REACT_APP_API_ENDPOINT || "https://esaapi.therapass.com/";

export const googleApiKey =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  "AIzaSyBWTtzXMxoDfNeXMyFo9OWkN9w3ZLxu9wo";

export const therapassApiEndpoint =
  process.env.REACT_APP_THERAPASS_APP_API_ENDPOINT ||
  "https://api.therapass.com";

export const showEsaLetterSelection =
  process.env.REACT_APP_SHOW_ESA_LETTER_SELECTION || true;

export const defaultEsaLetterType =
  process.env.REACT_APP_DEFAULT_ESA_LETTER_TYPE || "esa_letter_with_video_call";

/* export const interstitialLocation = ""; */
// available values: aftersearchbeforeresults, listing
